'use client'

import { GradientSpinner } from '@/components/ui/spinner'
import i18n from 'i18next'
import { type ReactNode, useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
interface TranslationsProviderProps {
	children: ReactNode
	locale: string
	namespaces: string[]
	resources: any
}

export function TranslationsProvider({ children, locale, namespaces, resources }: TranslationsProviderProps) {
	const [isInitialized, setIsInitialized] = useState(false)

	useEffect(() => {
		if (!i18n.isInitialized) {
			i18n
				.init({
					lng: locale,
					resources,
					ns: namespaces,
					fallbackLng: 'pt-BR', //pt_BR
					interpolation: {
						escapeValue: false,
					},
				})
				.then(() => {
					setIsInitialized(true)
				})
		} else {
			i18n.changeLanguage(locale).then(() => {
				setIsInitialized(true)
			})
		}
	}, [locale, namespaces, resources])

	if (!isInitialized) {
		return <GradientSpinner />
	}

	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
