import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/vercel/path0/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\",\"weight\":[\"400\",\"700\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/fonts.ts\",\"import\":\"Lora\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-lora\",\"weight\":[\"400\",\"700\"]}],\"variableName\":\"lora\"}");
;
import(/* webpackMode: "eager", webpackExports: ["BlogPosts"] */ "/vercel/path0/src/components/blog-posts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientHome"] */ "/vercel/path0/src/components/client-home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientLayout"] */ "/vercel/path0/src/components/client-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfirmDialog"] */ "/vercel/path0/src/components/confirm-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CreatableMultiSelect"] */ "/vercel/path0/src/components/creatable-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomInput"] */ "/vercel/path0/src/components/custom-input.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomSelect"] */ "/vercel/path0/src/components/custom-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/src/components/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/components/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageUpload"] */ "/vercel/path0/src/components/image-upload.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageChanger"] */ "/vercel/path0/src/components/language-changer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginForm"] */ "/vercel/path0/src/components/login-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/vercel/path0/src/components/mobile-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MultiSelect"] */ "/vercel/path0/src/components/multi-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostForm"] */ "/vercel/path0/src/components/post-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgramCard"] */ "/vercel/path0/src/components/program-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgramForm"] */ "/vercel/path0/src/components/program-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgramProtocolsTable"] */ "/vercel/path0/src/components/program-protocols-table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProtocolsList"] */ "/vercel/path0/src/components/protocols-list.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/providers/translations-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RoleSelectionDialog"] */ "/vercel/path0/src/components/role-select.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/vercel/path0/src/components/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserMenu"] */ "/vercel/path0/src/components/user-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["withAuth"] */ "/vercel/path0/src/components/with-auth.tsx");
